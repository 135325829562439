export const BaseUrls = {
  config: 'config/app/v1',
  account: 'account/v1',
  category: 'combo/category/v1',
  brand: 'combo/brand/v1',
  courtPlaceType: 'combo/courtplacetype/v1',
  skillLevel: 'combo/skilllevel/v1',
  user: 'account/user/v1',
  provider: 'court/provider/v1',
  court: 'court/court/v1',
  product: 'product/v1',
  locale: 'combo/locale/v1',
  currency: 'combo/currency/v1',
  country: 'combo/country/v1',
  role: "role/v1",
  attachment: "attachment/v1",
  attachmentGroup: "attachment/group/v1",
  location: "location/v1",
  homePage: "page/home/v1",
  webPage: "page/web/v1",
  userNotification: "notification/account/v1",
  sendNotification: "notification/send/v1",
  issue: "issue/v1",
  predefinedIssue: "issue/predefined/v1",
  address: "address/account/v1",
  dashboard: "dashboard/v1",
  report: "report/v1",
  reportView: "report/view/v1",
  contactUs: "contactus/v1",
  paymentProvider: "combo/payment-provider/v1",
  booking: "booking/v1",
}

export const BaseUrl = {
  get: name => BaseUrls[name],
}
